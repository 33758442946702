import { createReducer } from "@reduxjs/toolkit";
import { PARAM_TIMEZONE } from "../utils/constants";
import { localStorageSafe } from "../utils/localStorageUtils";

let initStates = {
  userName: "",
  username: "",
  // systemUid: localStorage.getItem("system_uid"),
  jobId: "",
  jobStatus: "",
  jobType: "",
  postingHandleData: [],
  firebaseUid: "",
  userTimezone: PARAM_TIMEZONE,
  configFound: false,
  jwt: "",
  refreshToken: "",
  userImageUrl: "",
  platformsLinked: {},
  frillSso: "",
  isUserIndian: false,
  userHasPosts: true,
  isSharedBrand: false,
  hasAdminRights: false,
  showUserOnlyPosts: false,
  userDomain: "",
  checklistEligible: false,
  attributionSource: ""
};
try {
  initStates = {
    userName: localStorage.getItem("username") || "",
    username: localStorage.getItem("useremail") || "",
    // systemUid: localStorage.getItem("system_uid"),
    jobId: localStorage.getItem("job_id") || "",
    jobStatus: localStorage.getItem("ws_status") || "",
    jobType: localStorage.getItem("job_type") || "",
    postingHandleData: JSON.parse(localStorage.getItem("ig_linked_handles")) || [],
    firebaseUid: localStorage.getItem("fbse_uid") || "",
    userTimezone: PARAM_TIMEZONE,
    configFound: false,
    jwt: localStorage.getItem("jwt") || "",
    refreshToken: localStorage.getItem("refresh_token") || "",
    userImageUrl: localStorage.getItem("user_img_url") || "",
    platformsLinked: JSON.parse(localStorage.getItem("platforms_linked")) || {},
    frillSso: localStorage.getItem("frillSSO") || "",
    isUserIndian: localStorage.getItem("isUserIndian") === "true" || false,
    userHasPosts: localStorage.getItem("user_has_posts") === "true" || false,
    isSharedBrand: false,
    hasAdminRights: false,
    showUserOnlyPosts: false,
    userDomain: localStorage.getItem("user_domain") || "",
    checklistEligible: localStorage.getItem("checklist_eligible") === "false" ? false : true,
    attributionSource: localStorage.getItem("attribution_source") || ""
  };
} catch (error) {
  console.error(error.message);
}

const setRefreshToken = (state, action) => {
  state.refreshToken = action.payload;
};
const updateJobId = (state, action) => {
  state.jobId = action.payload;
};
const setUserName = (state, action) => {
  state.userName = action.payload;
};
const setUsername = (state, action) => {
  state.username = action.payload;
};
const setSystemUid = (state, action) => {
  state.systemUid = action.payload;
};
const setJobType = (state, action) => {
  state.jobType = action.payload;
};
const setFrillSso = (state, action) => {
  state.frillSso = action.payload;
};

const setPostingHandleData = (state, action) => {
  state.postingHandleData = action.payload;
};
const setUserImageUrl = (state, action) => {
  state.userImageUrl = action.payload;
};
const setPlatformsLinked = (state, action) => {
  state.platformsLinked = action.payload;
};

const setAllData = (state, action) => {
  const indian_timezones = ["Asia/Colombo", "Asia/Calcutta", "Asia/Kolkata"];

  state.frillSso = action.payload.frillSso;
  state.isUserIndian = indian_timezones.includes(action.payload.userTimezone);
  state.userHasPosts = action.payload.userHasPosts;
  state.checklistEligible = action.payload.checklistEligible;
  state.attributionSource = action.payload.attributionSource;
  state.userName = action.payload.userName;
  state.username = action.payload.username;
  state.jobId = action.payload.jobId;
  state.jobType = action.payload.jobType;
  state.platformsLinked = action.payload.platformsLinked;
  state.jwt = action.payload.jwt;
  state.userImageUrl = action.payload.userImageUrl;

  if (action.payload.userDomain) {
    state.userDomain = action.payload.userDomain;
  }

  // Store values in localStorage
  if (state.isUserIndian) {
    localStorageSafe("setItem", "isUserIndian", "true");
  }

  try {
    localStorage.setItem("user_img_url", state.userImageUrl);
  } catch (error) {
    console.error(error.message);
  }
};

const updateJobStatus = (state, action) => {
  state.jobStatus = action.payload;
};

const updateConfigFound = (state, action) => {
  state.configFound = action.payload;
};

const setFirebaseUID = (state, action) => {
  state.firebaseUid = action.payload;
};

const setIsUserIndian = (state, action) => {
  // userTimezone is not of much use but for backward compatibility
  const indian_timezones = ["Asia/Colombo", "Asia/Calcutta", "Asia/Kolkata"];
  const tz = action.payload;
  if (indian_timezones.includes(tz)) {
    state.isUserIndian = true;
    localStorageSafe("setItem", "isUserIndian", "true");
  }
};

const setJWT = (state, action) => {
  state.jwt = action.payload;
};

const setUserHasPosts = (state, action) => {
  state.userHasPosts = action.payload;
};

const setIsSharedBrand = (state, action) => {
  state.isSharedBrand = action.payload;
};
const setHasAdminRights = (state, action) => {
  state.hasAdminRights = action.payload;
};
const setShowUserPostsOnly = (state, action) => {
  state.showUserOnlyPosts = action.payload;
};

const setUserDomain = (state, action) => {
  state.userDomain = action.payload;
};

const setChecklistEligible = (state, action) => {
  state.checklistEligible = action.payload;
};

const setAttributionSource = (state, action) => {
  state.attributionSource = action.payload;
};

export const reducer = createReducer(initStates, {
  setRefreshToken,
  updateJobId,
  updateConfigFound,
  updateJobStatus,
  setFirebaseUID,
  setUserName,
  setUsername,
  setSystemUid,
  setJobType,
  setPostingHandleData,
  setUserImageUrl,
  setPlatformsLinked,
  setAllData,
  setJWT,
  setFrillSso,
  setIsUserIndian,
  setUserHasPosts,
  setIsSharedBrand,
  setHasAdminRights,
  setShowUserPostsOnly,
  setUserDomain,
  setChecklistEligible,
  setAttributionSource
});
