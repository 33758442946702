import { tryCatchSafe } from "./utils/tryCatchUtils";

export const tiktokIdentify = async (id) => {
  const hashBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(id));
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const SHA_256 = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  window?.ttq?.identify({
    external_id: SHA_256 // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
  });
};

export const trackAdEcomLinked = (username) => {
  tryCatchSafe(() => window.ttq.track("ecom-linked", {}), "error ecom-linked  TikTok Pixel track");
  tryCatchSafe(() => window.fbq("track", "ecom-linked"), "error ecom-linked track");
  tryCatchSafe(
    () => window.gtag("event", "ecom-linked", { send_to: "AW-396003452/-zN1CNuJ8KEDEPyQ6rwB" }),
    "error ecom-linked track"
  );
  tryCatchSafe(
    () => window.gtag("event", "ecom-linked", { send_to: "G-PQJ6V7Z34C" }),
    "error ecom-linked track"
  );
};

export const trackAdSignup = (username) => {
  tryCatchSafe(() => window.ttq.track("signup", {}), "error in Tiktok Pixel signup");
  tryCatchSafe(
    () => window.gtag("event", "signup_success", { send_to: "AW-396003452/P0snCNba0ZYaEPyQ6rwB" }),
    "error signUp track"
  );
  tryCatchSafe(
    () => window.gtag("event", "signup_success", { send_to: "G-PQJ6V7Z34C" }),
    "error signUp track"
  );
};

export const trackAdInitiateCheckout = (username) => {
  tryCatchSafe(
    () => window.ttq.track("InitiateCheckout", {}),
    "error in tiktok Pixel checkout tracking"
  );
  tryCatchSafe(() => window.fbq("track", "InitiateCheckout"), "error in checkout tracking");
};

export const trackAdAfterPurchase = (props) => {
  const {
    username = "",
    amountPaid = 32,
    currency_code = "USD",
    planId = "lite",
    firebaseUid = "",
    linkedinId = ""
  } = props;
  tryCatchSafe(() => window.fpr("referral", { email: username }));
  tryCatchSafe(() =>
    window.ttq.track("CompletePayment", {
      value: amountPaid,
      currency: currency_code
    })
  );
  tryCatchSafe(() =>
    window.fbq("track", "Purchase", {
      value: amountPaid,
      currency: currency_code,
      external_id: firebaseUid
    })
  );
  tryCatchSafe(() =>
    window.gtag("event", "conversion", {
      send_to: "AW-396003452/-zN1CNuJ8KEDEPyQ6rwB", //ad
      value: amountPaid,
      currency: currency_code,
      transaction_id: firebaseUid
    })
  );
  tryCatchSafe(() =>
    window.gtag("event", "purchase", {
      send_to: "G-PQJ6V7Z34C", //analytics
      value: amountPaid,
      currency: currency_code,
      transaction_id: firebaseUid
    })
  );
  tryCatchSafe(() =>
    window.lintrk("track", {
      conversion_id: linkedinId
    })
  );
};
