import { useEffect, useState } from "react";
import { QueryClient } from "react-query";
import mainStore from "./MainStore/store";
import { check_inFrame } from "./RouterUtils";
// import EmbedContext from "./embed/embedContext";
// import { useSelector } from "react-redux";
// import { useContext } from "react";

export const loadSemrushScript = () => {
  // @deprecated
  // Set JWT on initial load for semrush from Semrush SDK
  if (!check_inFrame()) {
    // redirect to semrush app since semrush host is not allowed iframe
    // window.location.href = "https://www.semrush.com/app/ai-social-content-generator/";
  } else {
    const semrushScript = document.createElement("script");
    semrushScript.src = "https://static.semrush.com/app-center/sdk.js";
    semrushScript.async = true;
    semrushScript.onload = () => {
      // semrush script loaded
      window.SM.init()
        .then(async () => {
          // "SM" variable is available now.
          const jwt = await window.SM.client("getAccessToken");
          mainStore.dispatch({ type: "setJWT", payload: jwt });
          try {
            localStorage.setItem("jwt", jwt);
          } catch (error) {
            console.log("no localstorage allowed");
          }
          // SM.setAutoHeightUpdate({ enable: true })
        })
        .catch(() => console.log("error in init"));
    };
    // semrushScript.setAttribute('src', 'https://static.semrush.com/app-center/sdk.js');
    document.head.appendChild(semrushScript);
  }
};

export const loadKompassifyScript = () => {
  const kompassifyScript = document.createElement("script");
  kompassifyScript.type = "text/javascript";
  window.KOMPASSIFY_ACCOUNT_ID = "8c200c18-beea-4d4d-8065-a26430e269d5";
  kompassifyScript.src =
    "https://player.kompassify.com/kompassifyPlayer.js?uuid=8c200c18-beea-4d4d-8065-a26430e269d5";
  kompassifyScript.async = true;
  document.head.appendChild(kompassifyScript);
  return new Promise((resolve, reject) => {
    kompassifyScript.onload = () => {
      resolve();
    };
    kompassifyScript.onerror = () => {
      reject();
    };
  });
};
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return isOnline;
};
